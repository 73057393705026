import JsonPointer from 'json-pointer-rfc6901'
import Vue from 'vue'

import { compareResponsesAsc } from '@penbox-io/smart-form'
import {
  asArray,
  deepEqual,
  hasOwn,
  ifObject,
  ifString,
  isDefined,
  thrower,
} from '@penbox-io/stdlib'

import createState from './state'
import { byType, getValue } from './util'

export default {
  RESET(state, _payload) {
    Object.assign(state, createState())
  },

  LOAD_REQUEST(state, { requestId }) {
    if (state.requestId !== requestId) {
      Object.assign(state, createState())
    } else if (state.loading) {
      throw new Error('Request is already loading')
    }

    Object.assign(state, {
      requestId,
      loading: true,
      error: null,
    })
  },
  LOAD_SUCCESS(state, { requestId, data = null, included = [], force = false }) {
    if (state.requestId !== requestId && !force) return
    const responses = included.filter(byType('responses')).sort(compareResponsesAsc)
    const response = data?.type === 'responses' ? data : responses.pop() ?? null
    const request = data?.type === 'requests' ? data : included.find(byType('requests'))
    const access =
      (data?.type === 'request_access' ? data : included.find(byType('request_access'))) ||
      (state.access?.relationships.request.data.id === requestId ? state.access : null) ||
      thrower({ message: 'Access token required', status: 401 })
    const branding = included.find(byType('brandings')) ?? state.branding

    if (state.response?.id !== response?.id) {
      Object.assign(state, {
        active: null,
        pending: {},
        pendingFiles: {},
        uploads: {},
      })
    }

    Object.assign(state, {
      // Note: "request" might be null (if access not validated yet)
      requestId: access.relationships.request.data.id,
      loading: false,

      access,
      request,
      response,
      customization: included.find(byType('flow_customizations')) ?? null,
      company: included.find(byType('companies')) ?? null,
      flow: included.find(byType('flows')),
      responses,
      branding,
    })
  },
  LOAD_FAILURE(state, { requestId, error, ignoreInvalidAccess = undefined, force = false }) {
    if (state.requestId !== requestId && !force) return

    const isAccessInvalidError =
      error?.status === 403 &&
      (error.code === 'request-access-method-invalid' ||
        error.code === 'request-expired' ||
        error.code === 'request-archived')

    state.accessBranding ||= ifObject(error.meta?.['pnbx:requests:branding']) ?? null
    state.accessLocale ||= ifString(error.meta?.['pnbx:requests:locale']) ?? null
    state.accessOptions ||= ifObject(error.meta?.['pnbx:requests:access']) ?? null
    state.accessInactiveOrArchivedRequest ||= ifObject(error.meta?.['pnbx:requests:status']) ?? null

    Object.assign(state, {
      requestId,
      loading: false,
      error: isAccessInvalidError && ignoreInvalidAccess ? null : error,
    })
  },

  PATCH_REQUEST(state, payload) {
    Object.assign(state, {
      loading: true,
      error: null,
    })
  },
  PATCH_FAILURE(state, { error }) {
    Object.assign(state, {
      loading: false,
      error,
    })
  },
  PATCH_SUCCESS(state, payload) {
    Object.assign(state, {
      loading: false,
      error: null,
    })
  },
  RESPONSE_UPDATED(state, { response }) {
    if (response.id !== state.response?.id) return

    Object.assign(state, {
      response,
    })
  },

  SET_CURRENT_STEP(state, { stepId }) {
    if (state.loading) return
    state.active = state.active === stepId ? null : stepId
  },
  CONFIRMED_STEP(state, { stepId, attributes }) {
    // noop
  },

  SET_PENDING_VALUE(state, { key, value = null }) {
    if (deepEqual(state.pending[key], value)) return // noop

    // Avoid marking a step as "touched" when nothing has actually changed
    if (
      hasOwn(state.pending, key) ||
      // Allow setting the "user.locale" (or other) before creating the response
      !state.response ||
      // Next line will always be "true" when tehre is no defined saved value
      !deepEqual(getValue(state.response.attributes, key), value)
    ) {
      Vue.set(state.pending, key, value)
    } else {
      // Because we do not store the info "did the user confirmed the step"
      // and because of "auto save", we need to keep the field marked as "touched"
      // once it was editted until the step was actually "confirm"ed. Because of
      // this limitation, it is not possible to removed the "touched" state if the
      // user reverts all its changes (because we can't know if the saved values
      // were confirmed or auto saved).
      // Vue.delete(state.pending, key)
    }
  },

  UPDATE_PENDING(state, values) {
    const { attributes } = state.response

    for (const obj in values) {
      for (const prop in values[obj]) {
        const serverValue = attributes[obj][prop]
        const inboundValue = values[obj][prop]

        const key = `${obj}.${prop}`

        if (deepEqual(serverValue, inboundValue)) Vue.delete(state.pending, key)
        else Vue.set(state.pending, key, inboundValue)
      }
    }
  },

  FILE_UPDATE_REQUEST(state, { key, value = null }) {
    const shallowCopy = Array.isArray(value) ? [...value] : value
    Vue.set(state.pendingFiles, key, shallowCopy)
  },
  FILE_UPDATE_SUCCESS(state, { key, multiple = Array.isArray(state.pendingFiles[key]) }) {
    const files = asArray(state.pendingFiles[key])
    if (files.every((file) => file.id)) {
      const jsonArray = files.map((file) =>
        file instanceof File
          ? {
              size: file.size,
              type: file.type || undefined,
              name: file.name,

              id: file.id,
              metadata: file.metadata,
            }
          : file
      )

      Vue.delete(state.pendingFiles, key)
      Vue.set(state.pending, key, multiple ? jsonArray : jsonArray[0])
    }
  },
  FILE_UPDATE_STARTED(state, { key, queue = [] }) {
    Vue.set(state.uploads, key, { queue })
  },
  FILE_UPDATE_REMOVE(state, { key, file }) {
    file.state.loading = false

    const { queue } = state.uploads[key]
    const idx = queue.indexOf(file)
    if (idx !== -1) queue.splice(idx, 1)
  },
  FILE_UPDATE_ADD(state, { key, file }) {
    file.state.loading = true

    const { queue } = state.uploads[key]
    const idx = queue.indexOf(file)
    if (idx === -1) queue.push(file)
  },
  FILE_UPDATE_TERMINATED(state, { key }) {
    Vue.delete(state.uploads, key)
  },

  ATTACHMENT_UPLOAD_REQUEST(state, { file }) {
    file.state.error = null
    file.state.loading = { up: undefined, dn: undefined }
  },
  ATTACHMENT_UPLOAD_PROGRESS(_, { file, up = file.state.loading.up, dn = file.state.loading.dn }) {
    file.state.loading = { up, dn }
  },
  ATTACHMENT_UPLOAD_SUCCESS(state, { file }) {
    file.state.error = null
    file.state.loading = false
  },
  ATTACHMENT_UPLOAD_FAILURE(state, { file, error }) {
    file.state.error = error
    file.state.loading = false
  },

  FILL_STEP_DATA(state, { attachment, fill }) {
    const pending = state.pending.data
    const answers = state.response.attributes.data
    const source = attachment.attributes.metadata

    for (const prop in fill) {
      const { from, override = true } = fill[prop] || {}
      if (!from) continue // Validation
      if (override !== false || !(isDefined(answers[prop]) || isDefined(pending[prop]))) {
        const value = JsonPointer.get(source, from)
        Vue.set(pending, prop, value === undefined ? null : value)
      }
    }
  },

  START_LOADING(state) {
    state.loading = true
    state.error = null
  },

  END_LOADING(state) {
    state.loading = false
    state.error = null
  },
}
