import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'

/**
 * Shortcut to evaluate an expression using the ":not" method.
 *
 * @usage: ```! expression```
 */
class NotStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const firstCharCode = expr.charCodeAt(0)

    return firstCharCode === 33 /* ! */
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    return this.evaluateMethodCall({ ':not': `{${expr.slice(1).trimStart()}}` })
  }
}

export default new NotStringTransformer()
