import { createBuilder } from '@penbox-io/json-form'
import { asArray, flatten, ifNumber, ifString, isArray } from '@penbox-io/stdlib'

import { type Dict, isDict } from '../util/dict.js'

export {
  type Element,
  type Step,
  type ValueGetter,
  extractElementsValues,
  extractStepsValues,
  normalizeValue,
  stringifyFile,
  stringifyHelpable,
  stringifyTitle,
  stringifyValue,
} from '@penbox-io/json-form'

// We need a custom builder for two reasons:
// 1) To support penbox attachments as signature & download items
// 2) To support legacy features (visible: false, format: "number", etc.)
const builder = createBuilder(penScriptElementTransform)

export const buildElements = builder.buildElements
export const buildSteps = builder.buildSteps

function penScriptElementTransform(item: unknown): unknown {
  if (!isDict(item)) return null

  // Allow using `visible: false` to hide an element instead of `:if:then:else`.
  if (item.visible != null && item.visible !== true) return false

  // Legacy: "number" and "text" used to be the same.
  if (item.type === 'text' && item.format === 'number') {
    return { ...item, type: 'number' }
  }

  if (item.type === 'signature' && isArray(item.items)) {
    return { ...item, items: transformAttachmentItems(item.items) }
  }

  if (item.type === 'download' && isArray(item.items)) {
    return { ...item, items: transformAttachmentItems(item.items) }
  }

  return item
}

function transformAttachmentItems(input: unknown[]): unknown[] {
  return Array.from(flatten(input)).flatMap(transformAttachmentItem)
}

function transformAttachmentItem(input: unknown): unknown {
  if (!isDict(input)) return input
  if (!('attachments' in input)) return input

  return asArray(input.attachments, attachmentToItem, input)
}

// This is very pen-script specific. This whole file should be moved to pen-script.
function attachmentToItem(this: Dict, input: unknown): unknown {
  if (!isDict(input)) return null

  const id = ifString(input.id)
  if (!id) return null

  return {
    // pen-core uri
    uri: `attachments:${input.id}`,
    // Common "file item" stuff
    size: ifNumber(input.size),
    type: ifString(input.type),
    name: ifString(input.name),
    // Signature related stuff (if "signable": true meta)
    fill: this.fill,
    signatures: input.metadata?.signatures,
  }
}
