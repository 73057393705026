import { createMethodLegacyMultiple } from '../legacy'
import { isNumber, isString } from '../util'

function isLocaleValid(locale: string): boolean {
  try {
    return Intl.NumberFormat.supportedLocalesOf(locale).length > 0
  } catch (e) {
    return false
  }
}

/**
 * Format a number as a string with the following options: digits, locale and currency.
 *
 * If currency is used, number will rounded by default to 2 digits (can be overriden with :digits option).
 *
 * @usage: ```{ ':format-number': number, ':digits?': number, ':locale?': string, ':currency?': string }```
 * where:
 * - number: number: the number to format
 * - digits: number: the number of digits to display after the decimal point
 * - locale: string: the locale to use for formatting (must be a valid iso language code)
 * - currency: string: the currency code to use for formatting
 *
 * @example
 * ```{ ':format-number': 1234.5678 }``` => '1 234.5678'
 * ```{ ':format-number': 1234.5678, ':digits': 2 }``` => '1 234.57'
 * ```{ ':format-number': 1234.5678, ':locale': 'fr' }``` => '1 234,57'
 * ```{ ':format-number': 1234.5678, ':locale': 'en' }``` => '1,234.5678'
 * ```{ ':format-number': 1234.5678, ':locale': 'fr', ':currency': 'EUR' }``` => '1 234,57 €'
 *
 */
export default createMethodLegacyMultiple(
  ':format-number',
  [':locale', ':digits', ':currency'],
  function ({
    ':format-number': numberExpr,
    ':digits': decimalsExpr,
    ':locale': localeExpr,
    ':currency': currencyExpr,
  }) {
    const number = this.evaluate(numberExpr)
    if (!isNumber(number)) return undefined

    const currencyValue = this.evaluate(currencyExpr)
    const currency = isString(currencyValue) ? currencyValue : undefined
    const decimalsValue = this.evaluate(decimalsExpr)
    const digits =
      isNumber(decimalsValue) && decimalsValue >= 0 ? Math.min(decimalsValue, 20) : undefined
    const localeValue = this.evaluate(localeExpr)
    const locale = isString(localeValue) && isLocaleValid(localeValue) ? localeValue : undefined

    // if no locale is provided, we use the default locale 'fr' for backward compatibility with previous versions
    const formatedNumber = Intl.NumberFormat(locale ?? 'fr', {
      style: currency ? 'currency' : 'decimal',
      currency,
      maximumFractionDigits: digits,
    }).format(number)

    // in previous version of this code, we used a non-breaking space as a separator
    // if no locale was provided. We want to keep this behavior for backward compatibility
    if (!locale) {
      return formatedNumber.replaceAll(',', '.')
    }

    return formatedNumber
  }
)
