import { toNullProtoObject } from '@penbox-io/stdlib'

import type { DefinitionMap } from '../core'

import api from './elements/api.js'
import autocomplete from './elements/autocomplete.js'
import bankAccount from './elements/bank-account.js'
import button from './elements/button.js'
import card from './elements/card.js'
import checkbox from './elements/checkbox.js'
import checkboxes from './elements/checkboxes.js'
import choices from './elements/choices.js'
import currency from './elements/currency.js'
import date from './elements/date.js'
import datetime from './elements/datetime.js'
import download from './elements/download.js'
import file from './elements/file.js'
import hidden from './elements/hidden.js'
import image from './elements/image.js'
import number from './elements/number.js'
import paragraph from './elements/paragraph.js'
import phone from './elements/phone.js'
import radio from './elements/radio.js'
import rating from './elements/rating.js'
import signature from './elements/signature.js'
import submit from './elements/submit.js'
import text from './elements/text.js'
import toggle from './elements/toggle.js'
import toggles from './elements/toggles.js'
import webflow from './elements/webflow.js'

// elements can export their own types & utility functions
export * from './elements/api.js'
export * from './elements/autocomplete.js'
export * from './elements/bank-account.js'
export * from './elements/button.js'
export * from './elements/card.js'
export * from './elements/checkbox.js'
export * from './elements/checkboxes.js'
export * from './elements/choices.js'
export * from './elements/date.js'
export * from './elements/datetime.js'
export * from './elements/download.js'
export * from './elements/file.js'
export * from './elements/hidden.js'
export * from './elements/image.js'
export * from './elements/number.js'
export * from './elements/paragraph.js'
export * from './elements/phone.js'
export * from './elements/radio.js'
export * from './elements/rating.js'
export * from './elements/signature.js'
export * from './elements/submit.js'
export * from './elements/text.js'
export * from './elements/toggle.js'
export * from './elements/toggles.js'
export * from './elements/webflow.js'

export default toNullProtoObject({
  api,
  autocomplete,
  'bank-account': bankAccount,
  button,
  card,
  checkbox,
  checkboxes,
  choices,
  currency,
  date,
  datetime,
  download,
  file,
  hidden,
  image,
  number,
  paragraph,
  phone,
  radio,
  rating,
  signature,
  submit,
  text,
  toggle,
  toggles,
  webflow,
} satisfies DefinitionMap)
