import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'
import { findLast, findNext, findPairs, findQuotes } from './utils'

/**
 * Evaluate an if expression using a ternary operator.
 *
 * @usage: ```expr ? trueExpr : falseExpr```
 */
class IfStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)

    const interrogationMark = findNext(expr, 0, '?', quotes)

    return interrogationMark !== -1
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const interrogationMark = findNext(expr, 0, '?', quotes)

    const condition = expr.slice(0, interrogationMark).trim()
    const right = expr.slice(interrogationMark + 1).trim()

    const colon = findValidColon(right)

    const truthy = colon !== -1 ? right.slice(0, colon).trim() : right

    // Colon is optional, if not set, we fallback to undefined
    const falsy = colon !== -1 ? right.slice(colon + 1).trim() : undefined

    console.log('truthy:', truthy)
    console.log('falsy:', falsy)

    return this.evaluateMethodCall({
      ':if': `{${condition}}`,
      ':then': `{${truthy}}`,
      ':else': `{${falsy}}`,
    })
  }
}

function findValidColon(expr: string): number {
  const quotes = findQuotes(expr)

  const nextInterrogationMark = findNext(expr, 0, '?', quotes)

  // if there is no more interrogation mark, we need to find the last colon in the expression
  if (nextInterrogationMark === -1) {
    return findLast(expr, ':', quotes)
  }

  const nextColon = findNext(expr, 0, ':', quotes)

  // if there is a colon before the next interrogation mark, we need to take it
  if (nextColon !== -1 && nextColon < nextInterrogationMark) {
    return nextColon
  }

  return findLast(expr, ':', quotes)
}

export default new IfStringTransformer()
