import { ifString } from '@penbox-io/stdlib'

import { type Dict, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'
import { type FormError } from '../utils/error.js'

type Value = { [_ in string]?: null | Date }
type Options = {
  url: URL
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  headers: Dict<string>
  body: Dict<unknown>
  label?: string
  mapping?: Dict<unknown>
  submitOnChange?: boolean
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    url: new URL(input.url),
    method: ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'].includes(input.method) ? input.method : 'GET',
    headers: input.headers || {},
    body: input.body || {},
    label: ifString(input.label),
    mapping: input.mapping,
    submitOnChange: input.submit_on_change === true,
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  // Dunno what to do here
  return value as Value
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  return null
}

function normalize(element: Element): undefined | Value {
  return element.value || undefined
}

function stringify(element: Element): undefined | string {
  return element.value ? JSON.stringify(element.value) : undefined
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title)
}
